import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../types/State";
import { Appointment } from "../../types/Appointment";
import { ProcessModel } from "../../types/models/processModel/Process";
import { SalesAdresseeAccount } from "../../types/Account";
import { EventCalendar } from "../CalendlyComponent/CalendlyComponent";

export interface IStateCalendar {
  events: EventCalendar<Appointment>[];
  sales: SalesAdresseeAccount[];
  state: State;
  event?: EventCalendar<Appointment>;
  process: ProcessModel[];
  properties: { [key: string]: Record<string, string | boolean | number> };
}

export const initialState: IStateCalendar = {
  state: State.IDLE,
  events: [],
  process: [],
  sales: [],
  properties: {},
};

const slice = createSlice({
  initialState,
  name: "calendarReducer",
  reducers: {
    addEvent: (state, action: PayloadAction<EventCalendar<Appointment>>) => {
      const e = Object.assign([], state.events);
      e.push(action.payload);
      state.events = e;
      state.state = State.RESOLVED;
    },
    setState: (state, action: PayloadAction<State>) => {
      state.state = action.payload;
    },

    updateEvents: (state, action: PayloadAction<Appointment[]>) => {
      state.state = State.RESOLVED;
      state.events = action.payload.map((appointment) => {
        const startDate = appointment.start_date.toDate();
        const [SHours, SMinutes] = appointment.start_time.split(":") || [
          "0",
          "0",
        ];
        startDate.setHours(parseInt(SHours));
        startDate.setMinutes(parseInt(SMinutes));
        const endDate = appointment.due_date?.toDate() || startDate;
        const [EHours, EMinutes] = appointment.end_time?.split(":") || [
          "0",
          "0",
        ];
        endDate.setHours(parseInt(EHours));
        endDate.setMinutes(parseInt(EMinutes));

        return {
          title: appointment.summary,
          description: appointment.description,
          startDate,
          endDate,
          location: "",
          data: appointment,
        };
      });
    },
    changeEvent: (
      state,
      action: PayloadAction<EventCalendar<Appointment> | undefined>,
    ) => {
      state.event = action.payload;
    },
    setProcessModels: (state, action: PayloadAction<ProcessModel[]>) => {
      state.process = action.payload;
      state.state = State.RESOLVED;
    },
    setSales: (state, action: PayloadAction<SalesAdresseeAccount[]>) => {
      state.sales = action.payload;
      state.state = State.RESOLVED;
    },
    setProperties: (
      state,
      action: PayloadAction<{
        key: string;
        value: Record<string, string | boolean | number>;
      }>,
    ) => {
      state.properties = {
        ...state.properties,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
