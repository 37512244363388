import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEarth,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RangeNumber } from "../../types/utils";
import { EventTimeComponent } from "./EventTimeComponent";

export type WeekDays = RangeNumber<0, 7>;

export interface EventCalendar<Data> {
  startDate: Date;
  endDate: Date;
  title: string;
  description: string;
  location: string;
  onClickEvent?: (data: EventCalendar<Data>) => void;
  data?: Data;
}

type TimeAvailable = `30` | `00`;
type HourAvailable = RangeNumber<0, 25>;
type Time = `${HourAvailable}:${TimeAvailable}`;

export interface CalendlyProps<Data> {
  daysOfWeekDisabled?: WeekDays[];
  daysOfWeekHidden?: WeekDays[];
  datesOfMonthDisabled?: Date[];
  startTime?: Time;
  endTime?: Time;
  date?: Date;
  onChangeDate?: (date?: Date) => void;
  formatDate?: string;
  locale?: string;
  events?: EventCalendar<Data>[];
  onInit?: () => void;
  onStarted?: () => void;
  createEventLink?: string;
  eventComponent?: (props: EventCalendar<Data>) => JSX.Element;
  eventClick?: (
    event: EventCalendar<Data>,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  disabledBeforeToday?: boolean;
  children?: (d: Omit<DatesWrapperProps<Data>, "children">) => JSX.Element;
  currentEvent?: EventCalendar<Data>;
  onChangeEvent?: (event?: EventCalendar<Data>) => void;
}

function CalendlyComponent<Data>(props: CalendlyProps<Data>) {
  const { date = new Date() } = props;

  useEffect(() => {
    if (props.date === undefined) {
      props.onChangeDate?.(date);
    }
  }, [props.date]);

  const [month, changeMonth] = useState(() => {
    const month = new Date(date);
    month.setMinutes(0);
    date.setMinutes(0);
    return month;
  });

  return (
    <div style={{ width: "max-content", height: "700px" }} className="row">
      <div className="card p-4 rounded-2 overflow-hidden h-100">
        <div className="card-body h-100 pt-4">
          <DatesWrapper
            {...{
              ...props,
              date,
              month,
              onChangeMonth: changeMonth,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CalendlyComponent;

export interface DatesWrapperProps<Data>
  extends Omit<CalendlyProps<Data>, "date"> {
  date: Date;
  month: Date;
  onChangeMonth: (date: Date) => void;
  eventSelected?: EventCalendar<Data>;
  currentEvent?: EventCalendar<Data>;
  onChangeEvent?: (event?: EventCalendar<Data>) => void;
}

function DatesWrapper<Data>(props: DatesWrapperProps<Data>) {
  return (
    <div className="d-flex flex-wrap justify-content-center gap-5 h-100">
      {props.children?.(props)}
      <CalendarComponent {...props} />
      <EventTimeComponent {...props} />
    </div>
  );
}

// Days of the week
function DaysOfWeekSectionComponent<Data>({
  date,
  daysOfWeekHidden,
}: DatesWrapperProps<Data>) {
  const [weekDays] = useState(() => {
    const dateClone = new Date(date);
    //change day of the first day the week
    while (dateClone.getDay() !== 0) {
      dateClone.setDate(dateClone.getDate() - 1);
    }
    //create an array of 7 days
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(dateClone);
      date.setDate(date.getDate() + i);
      return date;
    });
  });

  return (
    <>
      {weekDays
        .filter((day) => !daysOfWeekHidden?.some((d) => d === day.getDay()))
        .map((date, i) => (
          <div key={i} className="day-name">
            {date.toFormattedDate("EEE")}
          </div>
        ))}
    </>
  );
}

// Days of the month
function DaysOfMonthSectionComponent<Data>({
  date,
  month,
  daysOfWeekDisabled,
  daysOfWeekHidden,
  onChangeDate,
  datesOfMonthDisabled,
  events,
  disabledBeforeToday,
}: DatesWrapperProps<Data>) {
  //generate days of the month with current date
  const days = useMemo(() => {
    const daysArray: Date[] = [];
    const dateClone = new Date(month);
    dateClone.setDate(1);
    //get day of the week
    const dayOfWeek = dateClone.getDay();
    //change day of the first day of month
    while (dateClone.getMonth() === month.getMonth()) {
      daysArray.push(new Date(dateClone));
      dateClone.setDate(dateClone.getDate() + 1);
    }

    Array.from({ length: dayOfWeek }, () => {
      const firstDate = new Date(daysArray[0]!);
      firstDate.setDate(firstDate.getDate() - 1);
      daysArray.unshift(firstDate);
    });

    const lastDay = daysArray[daysArray.length - 1]!;
    Array.from({ length: 6 - lastDay.getDay() }, (_, i) => {
      const lastDate = new Date(lastDay);
      lastDate.setDate(lastDate.getDate() + i + 1);
      daysArray.push(lastDate);
    });

    return daysArray.map((day) => {
      const date = new Date(day);
      date.setMinutes(0);
      return date;
    });
  }, [month]);

  const getStatesOfDate = (day: Date) => {
    //Selected state
    const isSelected = day.equals(date);
    //Disabled state
    let isDisabled =
      daysOfWeekDisabled?.some((d) => d === day.getDay()) ||
      datesOfMonthDisabled?.some((d) => day.equals(d));

    if (disabledBeforeToday) {
      isDisabled = isDisabled || day.lessThan(new Date());
    }

    const isNotVisible =
      daysOfWeekHidden?.some((d) => d === day.getDay()) ?? false;

    const isCurrentDate = day?.equals(new Date());

    const isUnavailable = month.getMonth() !== day.getMonth();

    const isEvent = events?.some((event) => event.startDate.equals(day));

    return {
      isSelected,
      isDisabled,
      isCurrentDate,
      isUnavailable,
      isEvent,
      isNotVisible,
    };
  };

  return (
    <>
      {days.map((day, i) => {
        const {
          isSelected,
          isDisabled,
          isCurrentDate,
          isUnavailable,
          isEvent,
          isNotVisible,
        } = getStatesOfDate(day);

        const formatDay = day.toFormattedDate("dd");

        switch (true) {
          case isUnavailable:
            return (
              <span className="calendar-days-btn-unavailable" key={i}>
                {formatDay}
              </span>
            );

          case isNotVisible:
            return null;

          default:
            return (
              <button
                key={i}
                disabled={isDisabled}
                aria-selected={isCurrentDate}
                aria-event-selected={isSelected}
                aria-event={isEvent}
                onClick={() => {
                  onChangeDate?.(day);
                }}
                className="calendar-days-btn"
              >
                {formatDay}
              </button>
            );
        }
      })}

      <div
        style={{
          gridColumn: "span 5",
        }}
        className="text-nowrap mt-5 text-lightgray"
      >
        <div className="font-weight-bolder mb-2">Time Zone:</div>
        <small className="ps-2">
          <FontAwesomeIcon icon={faEarth} />{" "}
          {Intl.DateTimeFormat().resolvedOptions().timeZone} -{" "}
          {Intl.DateTimeFormat().resolvedOptions().locale}
        </small>
      </div>
    </>
  );
}

function CalendarComponent<Data>(props: DatesWrapperProps<Data>) {
  const calendarRef = useRef<HTMLDivElement>(null);

  const { daysOfWeekHidden = [] } = props;

  const backMonth = () => {
    const prevMonth = new Date(props.month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);

    calendarRef.current?.classList.add("calendar-month-in");
    props.onChangeMonth(prevMonth);
  };

  const nextMonth = () => {
    const nextMonth = new Date(props.month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    calendarRef.current?.classList.add("calendar-month-out");
    props.onChangeMonth(nextMonth);
  };

  // animation to change month
  useEffect(() => {
    const calendar = calendarRef.current;

    if (calendar) {
      calendar.addEventListener("animationend", () => {
        calendar.classList.remove("calendar-month-in", "calendar-month-out");
      });
    }
  }, [props.month]);

  // If the days of the week are hidden, the number of columns is reduced
  const columns = 7 - daysOfWeekHidden.length;

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="calendar-months">
          <button
            disabled={!!props.currentEvent}
            onClick={backMonth}
            className="arrow"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div>{props.month.toFormattedDate("MMM yyyy")}</div>
          <button
            disabled={!!props.currentEvent}
            onClick={nextMonth}
            className="arrow"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div
          ref={calendarRef}
          style={{
            gridTemplateColumns: `repeat(${columns}, 35px)`,
          }}
          className="calendar-container mt-5"
        >
          <DaysOfWeekSectionComponent {...props} />
          <DaysOfMonthSectionComponent {...props} />
        </div>
      </div>
    </div>
  );
}
